import { OrganizationUserRole } from "docuchatcommontypes";

export default defineNuxtRouteMiddleware((_) => {
  const t = useNuxtApp().$i18n.t;
  const { user } = useUserStore();

  if (user?.organizationUserRole !== OrganizationUserRole.Admin
    && user?.organizationUserRole !== OrganizationUserRole.SuperAdmin) {
    return abortNavigation(t("base.errors.unauthorized"));
  }
});
